import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, Input, HostBinding } from '@angular/core';
const _c0 = ["*"];
const getIconClasses = props => {
  const classes = ['lf-icon'];
  if (props.name) {
    classes.push(`lf-icon-${props.name}`);
  }
  if (props.inline) {
    classes.push(`lf-icon_inline`);
  }
  return classes.join(' ');
};
class LfIconComponent {
  constructor() {
    /**
     * Should be icon optimized for usage inside text fragment
     */
    this.inline = false;
  }
  get className() {
    return getIconClasses(this);
  }
  static {
    this.ɵfac = function LfIconComponent_Factory(t) {
      return new (t || LfIconComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: LfIconComponent,
      selectors: [["lf-icon"], ["button", "lfIcon", ""]],
      hostVars: 2,
      hostBindings: function LfIconComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassMap(ctx.className);
        }
      },
      inputs: {
        name: "name",
        inline: "inline"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function LfIconComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LfIconComponent, [{
    type: Component,
    args: [{
      selector: 'lf-icon, button[lfIcon]',
      standalone: true,
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: "<ng-content></ng-content>\n"
    }]
  }], null, {
    name: [{
      type: Input
    }],
    inline: [{
      type: Input
    }],
    className: [{
      type: HostBinding,
      args: ['class']
    }]
  });
})();
var arrow_counter_clockwise_bold = 61697;
var arrow_left = 61698;
var arrow_right = 61699;
var arrow_up_bold = 61700;
var arrows_parallel_horizontal = 61701;
var asterisk = 61702;
var attach = 61703;
var block = 61704;
var calendar = 61705;
var caret_down_small_filled = 61706;
var caret_down_small = 61707;
var caret_down = 61708;
var caret_left_double = 61709;
var caret_left = 61710;
var caret_right_double = 61711;
var caret_right = 61712;
var caret_up_small = 61713;
var caret_up = 61714;
var chart = 61715;
var check_bold = 61717;
var check = 61718;
var clock = 61719;
var clockwise = 61720;
var close_bold = 61721;
var close = 61722;
var cloud_upload = 61723;
var copy = 61724;
var desktop = 61726;
var download = 61727;
var edit = 61728;
var email_send = 61729;
var file_check = 61730;
var file_error = 61731;
var file = 61732;
var filter = 61733;
var flag_filled = 61734;
var flag = 61735;
var globe = 61736;
var info_bold = 61737;
var link_break = 61738;
var link = 61739;
var log_out = 61740;
var minus_bold = 61741;
var minus_circle = 61742;
var minus = 61743;
var mobile = 61744;
var more_vertical = 61745;
var password_reset = 61746;
var plus_bold = 61747;
var plus = 61748;
var question_circle = 61749;
var repeat = 61750;
var scanner_barcode = 61751;
var search = 61752;
var settings = 61753;
var siren = 61754;
var store = 61755;
var subitems = 61756;
var task_square = 61757;
var unlock = 61758;
var user_add = 61759;
var user = 61760;
var visible_off = 61761;
var visible = 61762;
var warning_circle = 61763;
var warning_triangle_filled = 61764;
var data = {
  arrow_counter_clockwise_bold: arrow_counter_clockwise_bold,
  arrow_left: arrow_left,
  arrow_right: arrow_right,
  arrow_up_bold: arrow_up_bold,
  arrows_parallel_horizontal: arrows_parallel_horizontal,
  asterisk: asterisk,
  attach: attach,
  block: block,
  calendar: calendar,
  caret_down_small_filled: caret_down_small_filled,
  caret_down_small: caret_down_small,
  caret_down: caret_down,
  caret_left_double: caret_left_double,
  caret_left: caret_left,
  caret_right_double: caret_right_double,
  caret_right: caret_right,
  caret_up_small: caret_up_small,
  caret_up: caret_up,
  chart: chart,
  "check-circle": 61716,
  check_bold: check_bold,
  check: check,
  clock: clock,
  clockwise: clockwise,
  close_bold: close_bold,
  close: close,
  cloud_upload: cloud_upload,
  copy: copy,
  "delete": 61725,
  desktop: desktop,
  download: download,
  edit: edit,
  email_send: email_send,
  file_check: file_check,
  file_error: file_error,
  file: file,
  filter: filter,
  flag_filled: flag_filled,
  flag: flag,
  globe: globe,
  info_bold: info_bold,
  link_break: link_break,
  link: link,
  log_out: log_out,
  minus_bold: minus_bold,
  minus_circle: minus_circle,
  minus: minus,
  mobile: mobile,
  more_vertical: more_vertical,
  password_reset: password_reset,
  plus_bold: plus_bold,
  plus: plus,
  question_circle: question_circle,
  repeat: repeat,
  scanner_barcode: scanner_barcode,
  search: search,
  settings: settings,
  siren: siren,
  store: store,
  subitems: subitems,
  task_square: task_square,
  unlock: unlock,
  user_add: user_add,
  user: user,
  visible_off: visible_off,
  visible: visible,
  warning_circle: warning_circle,
  warning_triangle_filled: warning_triangle_filled
};
const list = [];
let name;
for (name in data) {
  list.push({
    name,
    code: data[name],
    hexCode: data[name].toString(16)
  });
}
const LfIconsMeta = {
  total: list.length,
  list
};

/**
 * Generated bundle index. Do not edit.
 */

export { LfIconComponent, LfIconsMeta };
