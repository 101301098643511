import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { Directive, Input, Component, ChangeDetectionStrategy, HostBinding } from '@angular/core';
import { LfIconComponent } from '@leafio/ui/icons';
const _c0 = ["lfBtn", ""];
const _c1 = ["*"];
const _c2 = ["lfIconBtn", ""];
const ABSTRACT_BUTTON_HOST = {
  '[attr.disabled]': 'disabled || null'
};
class LfAbstractButtonComponent {
  constructor() {
    this.accent = 'brand';
    this.mode = 'secondary';
    this.disabled = false;
  }
  static {
    this.ɵfac = function LfAbstractButtonComponent_Factory(t) {
      return new (t || LfAbstractButtonComponent)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: LfAbstractButtonComponent,
      inputs: {
        accent: "accent",
        mode: "mode",
        size: "size",
        disabled: "disabled"
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LfAbstractButtonComponent, [{
    type: Directive
  }], null, {
    accent: [{
      type: Input
    }],
    mode: [{
      type: Input
    }],
    size: [{
      type: Input
    }],
    disabled: [{
      type: Input
    }]
  });
})();
const getBtnClasses = props => {
  const classes = ['lf-btn'];
  if (props.mode) {
    classes.push(`lf-btn_${props.mode}`);
  }
  if (props.compact) {
    classes.push('lf-btn_compact');
  }
  if (props.accent) {
    classes.push(`lf-${props.accent}`);
  }
  if (props.size) {
    classes.push(`lf-${props.size}`);
  }
  return classes.join(' ');
};
class LfButton extends LfAbstractButtonComponent {
  constructor() {
    super(...arguments);
    this.compact = false;
  }
  get hostClasses() {
    return getBtnClasses(this);
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵLfButton_BaseFactory;
      return function LfButton_Factory(t) {
        return (ɵLfButton_BaseFactory || (ɵLfButton_BaseFactory = i0.ɵɵgetInheritedFactory(LfButton)))(t || LfButton);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: LfButton,
      selectors: [["button", "lfBtn", ""], ["a", "lfBtn", ""]],
      hostVars: 3,
      hostBindings: function LfButton_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵattribute("disabled", ctx.disabled || null);
          i0.ɵɵclassMap(ctx.hostClasses);
        }
      },
      inputs: {
        compact: "compact"
      },
      standalone: true,
      features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
      attrs: _c0,
      ngContentSelectors: _c1,
      decls: 1,
      vars: 0,
      template: function LfButton_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      dependencies: [CommonModule],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LfButton, [{
    type: Component,
    args: [{
      selector: 'button[lfBtn], a[lfBtn]',
      standalone: true,
      imports: [CommonModule],
      changeDetection: ChangeDetectionStrategy.OnPush,
      host: ABSTRACT_BUTTON_HOST,
      template: "<ng-content></ng-content>\n"
    }]
  }], null, {
    compact: [{
      type: Input
    }],
    hostClasses: [{
      type: HostBinding,
      args: ['class']
    }]
  });
})();
const getIconBtnClasses = props => {
  const classes = ['lf-btn', 'lf-btn_icon-only'];
  if (props.mode) {
    classes.push(`lf-btn_${props.mode}`);
  }
  if (props.accent) {
    classes.push(`lf-${props.accent}`);
  }
  if (props.size) {
    classes.push(`lf-${props.size}`);
  }
  return classes.join(' ');
};
class LfIconButton extends LfAbstractButtonComponent {
  get hostClasses() {
    return getIconBtnClasses(this);
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵLfIconButton_BaseFactory;
      return function LfIconButton_Factory(t) {
        return (ɵLfIconButton_BaseFactory || (ɵLfIconButton_BaseFactory = i0.ɵɵgetInheritedFactory(LfIconButton)))(t || LfIconButton);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: LfIconButton,
      selectors: [["button", "lfIconBtn", ""], ["a", "lfIconBtn", ""]],
      hostVars: 3,
      hostBindings: function LfIconButton_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵattribute("disabled", ctx.disabled || null);
          i0.ɵɵclassMap(ctx.hostClasses);
        }
      },
      inputs: {
        name: "name"
      },
      standalone: true,
      features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
      attrs: _c2,
      ngContentSelectors: _c1,
      decls: 2,
      vars: 1,
      consts: [[3, "name"]],
      template: function LfIconButton_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵelement(0, "lf-icon", 0);
          i0.ɵɵprojection(1);
        }
        if (rf & 2) {
          i0.ɵɵproperty("name", ctx.name);
        }
      },
      dependencies: [CommonModule, LfIconComponent],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LfIconButton, [{
    type: Component,
    args: [{
      selector: 'button[lfIconBtn], a[lfIconBtn]',
      standalone: true,
      imports: [CommonModule, LfIconComponent],
      changeDetection: ChangeDetectionStrategy.OnPush,
      host: ABSTRACT_BUTTON_HOST,
      template: "<lf-icon [name]=\"name\"></lf-icon>\n<ng-content></ng-content>\n"
    }]
  }], null, {
    name: [{
      type: Input
    }],
    hostClasses: [{
      type: HostBinding,
      args: ['class']
    }]
  });
})();

/**
 * Generated bundle index. Do not edit.
 */

export { LfButton, LfIconButton };
