import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import * as i0 from '@angular/core';
import { ElementRef, inject, ChangeDetectorRef, Renderer2, Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { first, EMPTY, catchError, map, shareReplay } from 'rxjs';
const _c0 = ["*"];
class LfSvgIconComponent {
  constructor() {
    this.html = inject(ElementRef).nativeElement;
    this.cdr = inject(ChangeDetectorRef);
    this.http = inject(HttpClient);
    this.doc = inject(DOCUMENT);
    this.renderer = inject(Renderer2);
    this.fill = '';
    this.stroke = '';
    this._width = '1.5em';
    this._height = '1.5em';
  }
  static {
    this.cache = new Map();
  }
  set name(value) {
    this._name = value;
    this._src = this._name ? `assets/lf-svg-icons/${this._name}.svg` : '';
    this.onIconUpdated();
  }
  set src(value) {
    this._name = '';
    this._src = value;
    this.onIconUpdated();
  }
  set width(value) {
    this._width = value;
    this.setupDimensions();
  }
  set height(value) {
    this._height = value;
    this.setupDimensions();
  }
  onIconUpdated() {
    if (!this._src) return;
    if (!LfSvgIconComponent.cache.has(this._src)) {
      LfSvgIconComponent.cache.set(this._src, this.loadIcon(this._src));
    }
    LfSvgIconComponent.cache.get(this._src).pipe(first()).subscribe(svg => this.insertContent(svg));
  }
  loadIcon(src) {
    return this.http.get(src, {
      responseType: 'text'
    }).pipe(catchError(err => EMPTY), map(text => this.createSvgElement(text)), shareReplay());
  }
  createSvgElement(text) {
    const div = this.doc.createElement('div');
    div.innerHTML = text;
    return div.querySelector('svg');
  }
  insertContent(svg) {
    this.destroyCurrentContent();
    this.svg = svg.cloneNode(true);
    this.setupDimensions();
    this.renderer.appendChild(this.html, this.svg);
  }
  destroyCurrentContent() {
    this.svg?.remove();
    delete this.svg;
  }
  setupDimensions() {
    this.svg?.setAttribute('width', String(this._width));
    this.svg?.setAttribute('height', String(this._height));
  }
  setupFillAndStroke(svg) {
    const fill = this.fill || window.getComputedStyle(this.html).color;
    svg.querySelectorAll('[fill]').forEach(el => el.setAttribute('fill', fill));
    this.stroke && svg.querySelectorAll('[stroke]').forEach(el => el.setAttribute('stroke', this.stroke));
  }
  static {
    this.ɵfac = function LfSvgIconComponent_Factory(t) {
      return new (t || LfSvgIconComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: LfSvgIconComponent,
      selectors: [["lf-svg-icon"]],
      hostAttrs: [1, "lf-svg-icon"],
      inputs: {
        name: "name",
        src: "src",
        width: "width",
        height: "height",
        fill: "fill",
        stroke: "stroke"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function LfSvgIconComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LfSvgIconComponent, [{
    type: Component,
    args: [{
      selector: 'lf-svg-icon',
      standalone: true,
      changeDetection: ChangeDetectionStrategy.OnPush,
      host: {
        class: 'lf-svg-icon'
      },
      template: "<ng-content></ng-content>\n"
    }]
  }], null, {
    name: [{
      type: Input
    }],
    src: [{
      type: Input
    }],
    width: [{
      type: Input
    }],
    height: [{
      type: Input
    }],
    fill: [{
      type: Input
    }],
    stroke: [{
      type: Input
    }]
  });
})();
var total = 121;
var list = [{
  name: "arrow-down",
  fileName: "arrow-down.svg",
  relativePath: "arrow-down.svg"
}, {
  name: "birdy/error",
  fileName: "error.svg",
  relativePath: "birdy/error.svg"
}, {
  name: "birdy/not_found",
  fileName: "not_found.svg",
  relativePath: "birdy/not_found.svg"
}, {
  name: "birdy/success",
  fileName: "success.svg",
  relativePath: "birdy/success.svg"
}, {
  name: "button/add-circle",
  fileName: "add-circle.svg",
  relativePath: "button/add-circle.svg"
}, {
  name: "button/add",
  fileName: "add.svg",
  relativePath: "button/add.svg"
}, {
  name: "button/back",
  fileName: "back.svg",
  relativePath: "button/back.svg"
}, {
  name: "button/check",
  fileName: "check.svg",
  relativePath: "button/check.svg"
}, {
  name: "button/copy-folder",
  fileName: "copy-folder.svg",
  relativePath: "button/copy-folder.svg"
}, {
  name: "button/copy",
  fileName: "copy.svg",
  relativePath: "button/copy.svg"
}, {
  name: "button/dropdown",
  fileName: "dropdown.svg",
  relativePath: "button/dropdown.svg"
}, {
  name: "button/edit",
  fileName: "edit.svg",
  relativePath: "button/edit.svg"
}, {
  name: "button/export",
  fileName: "export.svg",
  relativePath: "button/export.svg"
}, {
  name: "button/favorite",
  fileName: "favorite.svg",
  relativePath: "button/favorite.svg"
}, {
  name: "button/filter",
  fileName: "filter.svg",
  relativePath: "button/filter.svg"
}, {
  name: "button/import",
  fileName: "import.svg",
  relativePath: "button/import.svg"
}, {
  name: "button/lamp",
  fileName: "lamp.svg",
  relativePath: "button/lamp.svg"
}, {
  name: "button/link",
  fileName: "link.svg",
  relativePath: "button/link.svg"
}, {
  name: "button/more-horizontal",
  fileName: "more-horizontal.svg",
  relativePath: "button/more-horizontal.svg"
}, {
  name: "button/more",
  fileName: "more.svg",
  relativePath: "button/more.svg"
}, {
  name: "button/resend",
  fileName: "resend.svg",
  relativePath: "button/resend.svg"
}, {
  name: "button/reset",
  fileName: "reset.svg",
  relativePath: "button/reset.svg"
}, {
  name: "button/ring",
  fileName: "ring.svg",
  relativePath: "button/ring.svg"
}, {
  name: "button/search",
  fileName: "search.svg",
  relativePath: "button/search.svg"
}, {
  name: "button/settings",
  fileName: "settings.svg",
  relativePath: "button/settings.svg"
}, {
  name: "button/status/arrow-circle-down",
  fileName: "arrow-circle-down.svg",
  relativePath: "button/status/arrow-circle-down.svg"
}, {
  name: "button/status/arrow-circle-up",
  fileName: "arrow-circle-up.svg",
  relativePath: "button/status/arrow-circle-up.svg"
}, {
  name: "button/status/arrow-down",
  fileName: "arrow-down.svg",
  relativePath: "button/status/arrow-down.svg"
}, {
  name: "button/status/arrow-up",
  fileName: "arrow-up.svg",
  relativePath: "button/status/arrow-up.svg"
}, {
  name: "button/status/box",
  fileName: "box.svg",
  relativePath: "button/status/box.svg"
}, {
  name: "button/status/circle-check",
  fileName: "circle-check.svg",
  relativePath: "button/status/circle-check.svg"
}, {
  name: "button/status/clock",
  fileName: "clock.svg",
  relativePath: "button/status/clock.svg"
}, {
  name: "button/status/close",
  fileName: "close.svg",
  relativePath: "button/status/close.svg"
}, {
  name: "button/status/diagonal-vector",
  fileName: "diagonal-vector.svg",
  relativePath: "button/status/diagonal-vector.svg"
}, {
  name: "button/status/double-arrow-down",
  fileName: "double-arrow-down.svg",
  relativePath: "button/status/double-arrow-down.svg"
}, {
  name: "button/status/double-arrow-up",
  fileName: "double-arrow-up.svg",
  relativePath: "button/status/double-arrow-up.svg"
}, {
  name: "button/status/letter",
  fileName: "letter.svg",
  relativePath: "button/status/letter.svg"
}, {
  name: "button/status/minus",
  fileName: "minus.svg",
  relativePath: "button/status/minus.svg"
}, {
  name: "button/status/parking",
  fileName: "parking.svg",
  relativePath: "button/status/parking.svg"
}, {
  name: "button/status/pause",
  fileName: "pause.svg",
  relativePath: "button/status/pause.svg"
}, {
  name: "button/status/pipe-vector",
  fileName: "pipe-vector.svg",
  relativePath: "button/status/pipe-vector.svg"
}, {
  name: "button/status/plus",
  fileName: "plus.svg",
  relativePath: "button/status/plus.svg"
}, {
  name: "button/status/rotate-clock",
  fileName: "rotate-clock.svg",
  relativePath: "button/status/rotate-clock.svg"
}, {
  name: "button/status/sad-smile",
  fileName: "sad-smile.svg",
  relativePath: "button/status/sad-smile.svg"
}, {
  name: "button/status/slash",
  fileName: "slash.svg",
  relativePath: "button/status/slash.svg"
}, {
  name: "button/status/smile",
  fileName: "smile.svg",
  relativePath: "button/status/smile.svg"
}, {
  name: "button/status/wavy-check",
  fileName: "wavy-check.svg",
  relativePath: "button/status/wavy-check.svg"
}, {
  name: "button/suspend",
  fileName: "suspend.svg",
  relativePath: "button/suspend.svg"
}, {
  name: "button/table",
  fileName: "table.svg",
  relativePath: "button/table.svg"
}, {
  name: "button/trash",
  fileName: "trash.svg",
  relativePath: "button/trash.svg"
}, {
  name: "button/unblock",
  fileName: "unblock.svg",
  relativePath: "button/unblock.svg"
}, {
  name: "button/xlsx",
  fileName: "xlsx.svg",
  relativePath: "button/xlsx.svg"
}, {
  name: "chevron-left",
  fileName: "chevron-left.svg",
  relativePath: "chevron-left.svg"
}, {
  name: "chevron-right",
  fileName: "chevron-right.svg",
  relativePath: "chevron-right.svg"
}, {
  name: "close",
  fileName: "close.svg",
  relativePath: "close.svg"
}, {
  name: "cloud-error",
  fileName: "cloud-error.svg",
  relativePath: "cloud-error.svg"
}, {
  name: "cloud-upload",
  fileName: "cloud-upload.svg",
  relativePath: "cloud-upload.svg"
}, {
  name: "cluster/empty",
  fileName: "empty.svg",
  relativePath: "cluster/empty.svg"
}, {
  name: "cluster/list",
  fileName: "list.svg",
  relativePath: "cluster/list.svg"
}, {
  name: "cluster/report",
  fileName: "report.svg",
  relativePath: "cluster/report.svg"
}, {
  name: "copy",
  fileName: "copy.svg",
  relativePath: "copy.svg"
}, {
  name: "dashboard/arrow-up",
  fileName: "arrow-up.svg",
  relativePath: "dashboard/arrow-up.svg"
}, {
  name: "dashboard/breakeven",
  fileName: "breakeven.svg",
  relativePath: "dashboard/breakeven.svg"
}, {
  name: "dashboard/cart",
  fileName: "cart.svg",
  relativePath: "dashboard/cart.svg"
}, {
  name: "dashboard/dollar",
  fileName: "dollar.svg",
  relativePath: "dashboard/dollar.svg"
}, {
  name: "dashboard/export",
  fileName: "export.svg",
  relativePath: "dashboard/export.svg"
}, {
  name: "dashboard/filter",
  fileName: "filter.svg",
  relativePath: "dashboard/filter.svg"
}, {
  name: "dashboard/gmroi",
  fileName: "gmroi.svg",
  relativePath: "dashboard/gmroi.svg"
}, {
  name: "dashboard/import",
  fileName: "import.svg",
  relativePath: "dashboard/import.svg"
}, {
  name: "dashboard/income",
  fileName: "income.svg",
  relativePath: "dashboard/income.svg"
}, {
  name: "dashboard/more",
  fileName: "more.svg",
  relativePath: "dashboard/more.svg"
}, {
  name: "dashboard/piechart",
  fileName: "piechart.svg",
  relativePath: "dashboard/piechart.svg"
}, {
  name: "dashboard/receipt",
  fileName: "receipt.svg",
  relativePath: "dashboard/receipt.svg"
}, {
  name: "dashboard/sale",
  fileName: "sale.svg",
  relativePath: "dashboard/sale.svg"
}, {
  name: "dashboard/setting",
  fileName: "setting.svg",
  relativePath: "dashboard/setting.svg"
}, {
  name: "dashboard/table",
  fileName: "table.svg",
  relativePath: "dashboard/table.svg"
}, {
  name: "dashboard/update",
  fileName: "update.svg",
  relativePath: "dashboard/update.svg"
}, {
  name: "dashboard/wallet",
  fileName: "wallet.svg",
  relativePath: "dashboard/wallet.svg"
}, {
  name: "date/date-datepicker",
  fileName: "date-datepicker.svg",
  relativePath: "date/date-datepicker.svg"
}, {
  name: "date/range-datepicker",
  fileName: "range-datepicker.svg",
  relativePath: "date/range-datepicker.svg"
}, {
  name: "drag-indicator",
  fileName: "drag-indicator.svg",
  relativePath: "drag-indicator.svg"
}, {
  name: "empty-large",
  fileName: "empty-large.svg",
  relativePath: "empty-large.svg"
}, {
  name: "expand-less",
  fileName: "expand-less.svg",
  relativePath: "expand-less.svg"
}, {
  name: "expand-more",
  fileName: "expand-more.svg",
  relativePath: "expand-more.svg"
}, {
  name: "folder",
  fileName: "folder.svg",
  relativePath: "folder.svg"
}, {
  name: "history",
  fileName: "history.svg",
  relativePath: "history.svg"
}, {
  name: "info",
  fileName: "info.svg",
  relativePath: "info.svg"
}, {
  name: "insights/error",
  fileName: "error.svg",
  relativePath: "insights/error.svg"
}, {
  name: "insights/shift",
  fileName: "shift.svg",
  relativePath: "insights/shift.svg"
}, {
  name: "insights/success",
  fileName: "success.svg",
  relativePath: "insights/success.svg"
}, {
  name: "insights/warning",
  fileName: "warning.svg",
  relativePath: "insights/warning.svg"
}, {
  name: "left",
  fileName: "left.svg",
  relativePath: "left.svg"
}, {
  name: "logout",
  fileName: "logout.svg",
  relativePath: "logout.svg"
}, {
  name: "menu/box",
  fileName: "box.svg",
  relativePath: "menu/box.svg"
}, {
  name: "menu/classifier",
  fileName: "classifier.svg",
  relativePath: "menu/classifier.svg"
}, {
  name: "menu/clustering",
  fileName: "clustering.svg",
  relativePath: "menu/clustering.svg"
}, {
  name: "menu/company",
  fileName: "company.svg",
  relativePath: "menu/company.svg"
}, {
  name: "menu/dashboard",
  fileName: "dashboard.svg",
  relativePath: "menu/dashboard.svg"
}, {
  name: "menu/delivery",
  fileName: "delivery.svg",
  relativePath: "menu/delivery.svg"
}, {
  name: "menu/inbound",
  fileName: "inbound.svg",
  relativePath: "menu/inbound.svg"
}, {
  name: "menu/logo-abm",
  fileName: "logo-abm.svg",
  relativePath: "menu/logo-abm.svg"
}, {
  name: "menu/logo-leafio",
  fileName: "logo-leafio.svg",
  relativePath: "menu/logo-leafio.svg"
}, {
  name: "menu/matrix",
  fileName: "matrix.svg",
  relativePath: "menu/matrix.svg"
}, {
  name: "menu/menu",
  fileName: "menu.svg",
  relativePath: "menu/menu.svg"
}, {
  name: "menu/outbound",
  fileName: "outbound.svg",
  relativePath: "menu/outbound.svg"
}, {
  name: "menu/settings",
  fileName: "settings.svg",
  relativePath: "menu/settings.svg"
}, {
  name: "menu/statistic",
  fileName: "statistic.svg",
  relativePath: "menu/statistic.svg"
}, {
  name: "menu/strategy",
  fileName: "strategy.svg",
  relativePath: "menu/strategy.svg"
}, {
  name: "menu/tasks",
  fileName: "tasks.svg",
  relativePath: "menu/tasks.svg"
}, {
  name: "menu/user",
  fileName: "user.svg",
  relativePath: "menu/user.svg"
}, {
  name: "menu/users",
  fileName: "users.svg",
  relativePath: "menu/users.svg"
}, {
  name: "menu/work",
  fileName: "work.svg",
  relativePath: "menu/work.svg"
}, {
  name: "more-horiz",
  fileName: "more-horiz.svg",
  relativePath: "more-horiz.svg"
}, {
  name: "news",
  fileName: "news.svg",
  relativePath: "news.svg"
}, {
  name: "ok",
  fileName: "ok.svg",
  relativePath: "ok.svg"
}, {
  name: "read",
  fileName: "read.svg",
  relativePath: "read.svg"
}, {
  name: "report",
  fileName: "report.svg",
  relativePath: "report.svg"
}, {
  name: "settings/message",
  fileName: "message.svg",
  relativePath: "settings/message.svg"
}, {
  name: "simple-close",
  fileName: "simple-close.svg",
  relativePath: "simple-close.svg"
}, {
  name: "small-folder",
  fileName: "small-folder.svg",
  relativePath: "small-folder.svg"
}, {
  name: "support",
  fileName: "support.svg",
  relativePath: "support.svg"
}];
var data = {
  total: total,
  list: list
};
const LfSvgIconsMeta = data;

/**
 * Generated bundle index. Do not edit.
 */

export { LfSvgIconComponent, LfSvgIconsMeta };
